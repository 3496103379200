import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const SignsAndSymptoms = () => (
	<Layout>
		<SEO title="Lupus signs and symptoms" />
		<h1>Lupus signs and symptoms</h1>
		<p>
			Symptoms often vary over time and can periodically flare up. They include:
		</p>
		<ul>
			<li>Painful or swollen joints/non-erosive arthritis</li>
			<li>
				Fingertips and/or toes become pale or purple from the cold or stress
			</li>
			<li>Sores in the mouth or nose</li>
			<li>Low blood count/Hematologic disorder</li>
			<li>
				Red rash or colour change on the face, across the cheek or bridge of
				nose/Malar rash/Discoid rash
			</li>
			<li>Unexplained fever for several days</li>
			<li>Chest pain associated with breathing/Plueritic/Pericarditis</li>
			<li>Protein in the urine/Renal disorder</li>
			<li>Extreme fatigue — feeling tired all the time</li>
			<li>Sensitivity to the sun/Photosensitivity</li>
			<li>
				Depression, trouble thinking, and/or memory problems/Neurologic disorder
			</li>
			<li>Unusual hair loss, mainly on the scalp</li>
			<li>SLE specific antibodies</li>
		</ul>
	</Layout>
)

export default SignsAndSymptoms
